.frp_layout_sec {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.skeletonContainer {
  min-height: 50 vh;
}

.frp_main {
    display: flex;  
    flex-wrap: wrap;
  
    .frp_layout_sidebar {
      width: 100%;
      overflow: hidden;
      display: none;
    }
    .frp_layout_spacer {
      width: 100%;
    }
    .frp_layout_facets {
      width: 100%;
      padding-bottom: 1rem;
    }
  }

.frp_layout_extended {
  width: 100%;
  padding-top: 2rem;
}

@media (min-width: 1024px) {
  .frp_main {
    scroll-margin-top: 2rem;
    flex-wrap: nowrap;
    .frp_layout_sidebar {

      // position: sticky;
      align-self: flex-start;
      top: 1vh;
      //prevent collision with mobile menu bar
      // max-height: 90vh;
      // overflow-y: auto;
      display: block;
      width: 35%;
    }

    .frp_layout_facets {
      max-width: 80%;
    }

    .frp_layout_spacer {

      width: 1%;
    }

    .frp_layout_results {

      position: sticky;
      align-self: flex-start;
      top: 2rem;
      padding-top: 0;
      padding-bottom: 0;
      width: 64%;

      display: flex;
      flex-direction: row;

      flex-wrap: wrap;
      justify-content: space-between;
      align-content: space-around;
      align-items: center;
    }
  }
}

@media (min-width: 1024px) {
//mobile menu bar gone, time to partay
.frp_main {
.frp_layout_sidebar {
  // max-height: 98vh;
  max-width: 320px;
}
}
}

@media (min-width: 1336px) {
  .frp_main {

      .frp_layout_sidebar {
          width: 25%;
          max-width: 320px;
          display: block;
          overflow: visible;
          // max-height: calc(100vh - 1rem);
          // overflow-y: scroll;


      }
      .frp_layout_spacer {

      }
      .frp_layout_results {
          width: 72%;
      }
  }


}
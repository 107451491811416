.top_divider {
    margin-bottom: 1rem;
}

.middle_divider {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.bottom_divider {
    margin-top: 1rem;
}


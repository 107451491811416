.rule {
  padding: 0.1rem 0.25rem;
}

.card {
  display: inline-block;
}

.rulesWrapper {
  display: inline-block;
  cursor: pointer;
}

.popoverContent {
  padding: 0.5rem;
  min-width: 300px;
  max-height: 400px;
  overflow-y: auto;
}

.ruleGroup {
  margin-bottom: 1rem;
}

.ruleGroupTitle {
  margin-bottom: 0.5rem;
}

.ruleList {
  padding-left: 0;
  list-style-type: none;
}

.ruleItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;
  padding: 0.5rem;
  background-color: #f5f5f5;
  border-radius: 4px;

  &:last-child {
    margin-bottom: 0;
  }
}

.ruleInfo {
  flex-grow: 1;
}

.ruleName {
  font-weight: bold;
}

.ruleOrder {
  color: #666;
}

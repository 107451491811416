.html {
  max-width: 60ch;
}
.explaination {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.textLink{
  display: inline;
}
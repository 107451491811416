.h2 {
    display: none;
}

.section {
    width: 100%;
    margin-top: 1rem;
    display: grid;
    gap: 1rem;
    grid-template-columns: 100%;
}

.gridView {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    width: 100%;
    margin-top: 1rem;
}

.productSectionSkeleton {
    width: 100%;
}

.feedback {
    width: 100%;
    display: flex;
    justify-content: center;
}
.searchProviderBoostingDataModal{
    width: 80vw;
    padding: 2rem;
    max-height: 60vh;
    overflow-x: scroll;
}

.boostingCard{
    margin-bottom: 1rem;
    width: 100%;
}

.listSection{
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;
    margin-bottom: 0.5rem;
}

.sku{
    cursor: pointer;
}

.smallMargin{
    padding: 0.25rem;
}

.boostingRulesButton{
    position: fixed;
    bottom: 2rem;
    left: 2rem;
}
.section{
    margin-bottom: 2rem;
}
.sidebar_drawer {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.header {
    padding: 1rem;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
}

.facetGroup{
    display: flex;
    flex-direction: column;
}

.body {
    flex-grow: 1;
}

.divider {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.resultsDivider {
    margin-bottom: 1rem;
}

.filterHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.stickyButton{
    position: sticky;
    bottom: 0px;
}

.resultsButton{
    margin: 0rem 0.5rem 0.5rem 0.5rem;
}

.stickyHeaderInDrawer{
    padding-top: 0.25rem;
}

.accordionSvgOpen_true{
    svg{
        transform: rotate(-90deg);
    }
    .sideBarFacetOptions {
        svg{
            transform: rotate(0deg);
        }
    }
}

.accordionSvgOpen_false{
    svg{
        transform: rotate(90deg);
    }
    .sideBarFacetOptions {
        svg{
            transform: rotate(0deg);
        }
    }
}
.cardContentsNoVehicle {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.title {
  width: 100%;
  justify-content: start;
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
}

.exactFitText {
  grid-row-start: 1;
  grid-column: 2;
}

.svg {
  margin-right: 0.5rem;
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column: 1;
  height: 2rem;
  border-radius: 100%;
}

.card {
  display: block;
  margin-bottom: 2rem;
  margin-top: 0;
  position: relative;
}

.changeSelected {
  margin-right: 0.5rem;
}

.itemText {
  display: inline-flex;
  align-items: center;
}

.spaceBetween {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
}

.changeSelected {
  margin-top: 0.5rem;
}

.spaceBetweenUnselected {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
}

@media (min-width: 550px) {
  .changeSelected {
    margin-top: 0;
  }

  .spaceBetweenUnselected {
    flex-direction: row;
  }
}

@media (min-width: 1275px) {
  .itemText {
    display: inline-flex;
    align-items: center;
  }
}

.recall {
  padding: 0.1rem 0.25rem;
}

.card {
  display: inline-block;
}

.recallsWrapper {
  display: inline-block;
  cursor: pointer;
}

.popoverContent {
  padding: 0.5rem;
  min-width: 300px;
}

.recallGroupTitle {
  margin-bottom: 0.5rem;
}

.recallList {
  padding-left: 0;
  list-style-type: none;
}

.recallItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;
  padding: 0.5rem;
  background-color: #f5f5f5;
  border-radius: 4px;

  &:last-child {
    margin-bottom: 0;
  }
}

.recallInfo {
  flex-grow: 1;
}

.recallName {
  font-weight: bold;
}

.recallDetails {
  color: #666;
}

.configureLink {
  padding: 0.25rem 0.5rem;
  background-color: blue;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-size: 0.75rem;
  transition: background-color 0.2s;
  margin-left: 1rem;

  &:hover {
    background-color: #0056b3;
    cursor: pointer;
  }
}

.addLink {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
  margin-top: 10px;

  &:hover {
    color: #0056b3;
    text-decoration: underline;
  }
}

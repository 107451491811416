.campaign {
    width: 100%;
    margin: auto;

    * {
        margin: 0;
    }
}

.fullWidth {
    width: 100%;
}

.wrapper {
    width: 100%;
    margin-top: 16px;
}

.jsonWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
}

.rowWrapper {
    width: 100%;
}

.carouselImageWrapper {
    display: inline-flex;
    justify-content: center;
    width: 100%;
    margin: 0.25rem;
    position: relative;
    min-height: 200px;
}

.imageWrapper {
    display: inline-flex;
    justify-content: center;
    width: 100%;
    margin: 0.25rem;
    position: relative;
    container-name: image;
    container-type: inline-size;
    margin-bottom: 1rem;
}

@container image (min-width: 200px){
    .overlayText {
        font-size: 5cqw;
    }
    .overlaySubText {
        font-size: 3cqw;
    }
}

.mobileScrollCarouselItem {
    min-width: 33%;
}

.image,
.mobileImage {
    height: auto;
    width: 100%;
    justify-content: center;
}

.hideMobile {
    display: none;
}

.hideDesktop {
    display: block;
    width: 100%;
}

.desktopImage {
    display: none;
}

.titleWrapper {
    display: flex;
    flex-direction: column;
    max-width: 100%;
}

.title {
    display: flex;
    justify-content: center;
}

.articles {
    margin-top: 2rem;
    margin-bottom: 2rem;
    max-width: 100%;
    width: 100%;
}

.overlayWrapper {
    position: absolute; 
    top: 0;
    bottom: 0;
    line-height: 1;
    padding-left: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.overlayText, .overlaySubText {
    line-height: inherit;
}

.campaignWrapper {
    width: 100%;
}

.mobileImageWrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.hide{
    display: none;
}

@media(min-width: 800px) {
    .desktopImage {
        display: flex;
        justify-content: center;
        height: auto;
        width: 100%;
    }
    .rowWrapper {
        width: 100%;
        display: inline-flex;
    }
    .mobileImageWrapper {
        display: none;
    }

    .desktopImageWrapper{
        width: 100%;
    }

    .mobileImage {
        display: none;
    }

    .hideMobile {
        display: block;
        width: 100%;
    }

    .hideDesktop {
        display: none;
    }
}

.cardImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.cardWrapper {
    display: flex;
    margin-top: 1rem;
    margin-bottom: -1rem;
    overflow-x: hidden;
    overflow-y: hidden;
}

.campaignCard {
    max-width: 100%;
    flex-grow: 1;
}


@media (min-width: 600px) {
    .campaignCard {
        flex: 1 0 45%;
        max-width: 50%;
    }
}

@media (min-width: 800px) {
    .campaignCard {
        flex: 1 0 45%;
        max-width: 50%;
    }
    .imageWrapper {
    }
}

@media (min-width: 1260px) {
    .campaignCard {
        flex: 1 0 30%;
        max-width: 33%;
    }
    .imageWrapper {
    }
}

@media (min-width: 1500px) {
    .campaignCard {
        flex: 1 0 30%;
        max-width: 33%;
    }
    .imageWrapper {
    }
}

@media (min-width: 1800px) {
    .campaignCard {
        flex: 1 0 22%;
        max-width: 25%;
    }
    .imageWrapper {
    }
}

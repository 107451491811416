.verticalProductCard{
    max-width: 100%;
    flex-grow: 1;
}
.productCardSkeleton{
    margin-top: 1rem;
    width: 100%;
}

@media (min-width: 600px) {
    .verticalProductCard{
        flex: 1 0 45%;
        max-width: 50%;
    }
}

@media (min-width: 1260px) {
    .verticalProductCard{
        flex: 1 0 30%;
        max-width: 33%;
    }
}

@media (min-width: 1500px) {
    .verticalProductCard{
        flex: 1 0 30%;
        max-width: 33%;
    }
}

@media (min-width: 1800px) {
    .verticalProductCard{
        flex: 1 0 22%;
        max-width: 25%;
    }
}
.options {
    display: none;
}

.count {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: auto;
}

.sortBy {
    display: flex;
    align-items: center;
}

.sortByLabel {
    margin-right: 0.5rem;
}

@media (min-width: 835px) {
    .options {
        display: flex;
        width: 100%;
        justify-content: flex-end;
    
        form {
            width: max-content;
            float: right;
        }
    }

    .productCardLayoutToggles{
        margin-right: 1rem;
    }

    .layoutToggleButton{
        padding: 0.375rem 0.625rem;
        margin-top: 0px;
        margin-right: 0.5rem;
    }

    .divider {
        margin-top: 0.75rem;
    }
}


@media (max-width: 835px) {
    .hideMobile {
        display: none;
    }
}
.card {
  display: none;
}

@media (min-width: 768px) {
  .card {
    display: inline-flex;
    margin-left: 0.5rem;
    padding: 0.5rem;
    width: 200px;
  }

  .rulesWrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: center;
  }

  .rule {
    max-width: 100%;
    flex-shrink: 1;
    padding: 0.1rem 0.25rem;
    overflow: hidden;
    width: 100%;
    word-wrap: break-word;
    white-space: normal;
  }
}
.option {
  margin-top: 0.75rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

}

.swatch {
  margin-left: 0.25rem;
}

.switchGroup {
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;

  & * {
    cursor: pointer;
  }
}

.checkBoxGroup {
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;

  & * {
    cursor: pointer;
  }
}

.checkBoxGroup label {
  -webkit-transition: margin-left 200ms ease-out; /* Safari prior 6.1 */
  transition: margin-left 200ms ease-out;
}

.checkBoxGroup:hover label {
  margin-left: 0.5rem;
}

.single_select {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  & * {
    cursor: pointer;
  }

  display: inline-flex;
  margin-left: 0rem;

  -webkit-transition: margin-left 200ms ease-in-out; /* Safari prior 6.1 */
  transition: margin-left 200ms ease-in-out;

  &:hover {
    margin-left: 0.5rem;
  }
}

.hidden {
  display: none;
}

.multiSelectCount {
  margin-left: auto;
}

.pinned {
  margin-left: 0.25rem;
  padding: 0.1rem 0.25rem;
}
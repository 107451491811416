.cardContainer{
    display: flex;
    grid-column-gap: 1%;
    overflow-y: hidden;
    grid-template-columns: repeat(min(1,var(--numCols)), calc((100% / min(1,var(--numCols))) - 1%));
}

.container{
    padding-top: 0;
}

.option{
    height: fit-content;
    flex-shrink: 0;
    border: 1px solid lightgray;
    border-radius: 4px;
    padding: 0.75rem 1rem;
    margin-top: 0px;
    cursor: pointer;
    font-weight: bold;
    &:hover{
        border: 1px solid rgba(0, 0, 0, 0.2);
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
}

.cardContainer::-webkit-scrollbar {
    display: none;
  }

.optionValue{
    margin-right: 1.5rem;
}

.coveringSpinnerWrapper{
    border: none;
    margin-bottom: 0;
}

.moreWrapper{
    display: none;
}

.title{
    margin-bottom: 0.5rem;
}

@media(min-width: 600px){
    .container{
        //margin-bottom: 0.5rem;
        width: 100%;
        display: flex;
        align-items:center;
        justify-content: space-between;
        border-radius: 4px;
        padding-top: 1.5rem;
        margin-bottom: 0.5rem;
    }
    
    .coveringSpinnerWrapper{
        border-radius: 4px;
        padding-left: 1%;
    }

    .title{
        margin-bottom: 0.5rem;
        position: absolute;
        top: -1rem;
        left: 1rem;
        background-color: white;
        padding: 0 0.5rem;
    }
    
    .cardContainer{
        display: grid;
        grid-column-gap: 1%;
        overflow-y: hidden;
        grid-template-columns: repeat(var(--numCols), calc((100% / var(--numCols)) - 1%));
    }

    .collapsed{
        max-height: 115px;
    }
    
    .cardContainer::-webkit-scrollbar {
        display: none;
      }
      
    
    .facet{
        width: 100%;
    }
    
    .option{
        border: 1px solid rgb(172, 171, 171);
        border-radius: 4px;
        padding: 0.75rem 1rem;
        margin-top: 0px;
        cursor: pointer;
        font-weight: bold;
        &:hover{
            border: 1px solid rgba(0, 0, 0, 0.2);
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
        }
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.5rem;
    }   

    
    .skip{
        height: 100%;
        padding: 1rem;
        border-radius: 4px;
        cursor: pointer;
        border: 1px solid darkgray;
        &:hover{
            border: 1px solid rgba(0, 0, 0, 0.2);
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
        }
        flex-shrink: 0;
    }
    
    .moreWrapper{
        display: flex;
        justify-content: center;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        bottom: 1;
    }
    .more{
        border-radius: 1rem;
    }
}

@media(min-width: 1360px) and (max-width: 1800px){
    .cardContainer{
        display: grid;
        grid-column-gap: 1%;
        overflow-y: hidden;
        grid-template-columns: repeat(min(3,var(--numCols)), calc((100% / min(3,var(--numCols))) - 1%));
    }
}

@media(min-width: 600px) and (max-width: 1360px){
    .cardContainer{
        display: grid;
        grid-column-gap: 1%;
        overflow-y: hidden;
        grid-template-columns: repeat(min(2,var(--numCols)), calc((100% / min(2,var(--numCols))) - 1%));
    }
}



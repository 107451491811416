.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
}

.area {
  padding: 0.1rem 0.25rem;
}

.card {
  display: inline-block;
}

.recallsWrapper {
  display: inline-block;
  cursor: pointer;
}

.popoverContent {
  padding: 0.5rem;
  min-width: 300px;
}

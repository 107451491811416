.show_more {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  border: none;
  padding: 0;
}

.coverSpin {
  display: flex;
}

.facetContainer {
  display: flex;
  align-items: center;
}

.facetName {
  margin-right: 4px;
}

.pinned {
  margin-left: 0.25rem;
  padding: 0.1rem 0.25rem;
}

.facetOptionSkeleton {
  margin-top: 0.75rem;
  margin-bottom: 0;
}
.showMobile {
  display: block;
}

.hideMobile {
  display: none;
}

.completeYourVehicle {
  margin-top: 0.5rem;
  width: 100%;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.completeVehicleSelectors {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 0;
  flex-wrap: nowrap;
  align-items: flex-end;
  flex-direction: column;
}

.cardContents {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.12);
}

.title {
  display: grid;
  align-items: center;
  column-gap: 0.25rem;
  width: 100%;
  justify-content: start;
}

.exactFitText {
  grid-row-start: 1;
  grid-column: 2;
}

.vehicleText {
  grid-row-start: 2;
  grid-column: 2;
}

.svg {
  margin-right: 0.5rem;
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column: 1;
  height: 2rem;
  border-radius: 100%;
}

.selectWrapper,
.viewResults {
  width: 100%;
  margin-top: 0.5rem;
}

.card {
  display: block;
  margin-bottom: 2rem;
  margin-top: 0;
  position: relative;
}

.divider {
  margin-top: 0.5rem;
  position: absolute;
  left: 0;
  top: 2.25rem;
}

.title {
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
}

.selectWrapper {
  display: flex;
  flex-direction: column;
  margin-right: 0;
}

.nowrap {
  white-space: nowrap;
}

@media (min-width: 500px) {
  .completeVehicleSelectors {
    flex-direction: row;
  }

  .selectWrapper {
    margin-right: 0.5rem;
  }
}

@media (min-width: 1275px) {
  .hideMobile {
    display: block;
  }

  .completeYourVehicle {
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  .completeVehicleSelectors {
    margin-top: 0;
    flex-wrap: nowrap;
    align-items: flex-end;
  }

  .selectWrapper {
    margin-right: 0.5rem;
  }
}

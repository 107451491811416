.vehicleButton {
  display: none;
}

.cardContents {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.12);
}

.title {
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
  width: 100%;
  justify-content: start;
}

.svg {
  margin-right: 0.5rem;
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column: 1;
  height: 2rem;
  border-radius: 100%;
}

.card {
  display: block;
  margin-bottom: 2rem;
  margin-top: 0;
  position: relative;
}

.switch {
  margin-left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  padding-left: 44px;
}

.buttonWrapper {
  display: none;
}

.changeSelected {
  margin-right: 0.5rem;
}

.vehicleButton {
  display: unset;
}

.itemText {
  display: inline-flex;
  align-items: center;
}

.completeVehicleText {
  padding-left: 0.25rem;
  white-space: nowrap;
  height: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.vehicleSubtext {
  overflow: hidden;
  text-overflow: ellipsis;
}

.spaceBetween {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
}

.editIcon {
  display: flex;
  margin-left: 0.5rem;
  margin-top: 0.2rem;
  cursor: pointer;
}

.withSubtext {
  align-self: flex-start;
}

.onlyTitle {
  align-self: center;
}

.resultsTitle {
  padding-bottom: 0.5rem;
}

.controls {
  padding-top: 0.5rem;
}

.changeSelected {
  margin-top: 0.5rem;
}

.nowrap {
  white-space: nowrap;
}

@media (min-width: 550px) {
  .changeSelected {
    margin-top: 0;
  }
}

@media (min-width: 1275px) {
  .hideDesktop {
    display: none;
  }

  .buttonWrapper {
    display: flex;
    margin-left: 5px;
  }

  .itemText {
    display: inline-flex;
    align-items: center;
  }

  .spaceBetween {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .completeVehicleText {
    padding-left: 0.25rem;
    white-space: nowrap;
  }

  .controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 0;
  }

  .switch {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 1rem;
    margin-left: 1rem;
    white-space: nowrap;
    padding-left: 0;
  }

  .spaceBetween {
    flex-direction: row;
  }
}

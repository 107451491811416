.sticky_header {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    max-width: 100%;
    position: relative;
    &:after{
        content: '';
        position: absolute;
        width: 1.5rem;
        right: 0;
        top: 0;
        height: 40px;
        background: linear-gradient(90deg,var(--i),var(--b));
        pointer-events: none;
    }
}

.sort{
    display: flex;
    align-items: center;
    position: absolute;
}

.sortLink {
    padding-left: .5rem;
    white-space: nowrap;
}

.scroll{
    display: flex;
    align-items: center;
    margin-left: 7.75rem;
    overflow-x: auto;
}

.facetSelected{
    margin-left: 10rem;
}

.onlyChips{
    display: flex;
    align-items: center;
    overflow-x: auto;
    
}

.scroll::-webkit-scrollbar { display: none; }
.onlyChips::-webkit-scrollbar { display: none; }

.divider {
    margin-top: 0rem;
}

.chip_divider {
    display: flex;
    align-items: center;
    border-right-style: solid;
    border-right-width: 1px;
    padding-right: 9px;
    padding-left: 0rem; 
    height: 42px;
    margin-top: -1px;
}

@media (min-width: 835px) {
    .sticky_header.narrow {
        display: none;
    }

    .stickyHeaderContainer.narrow {
        display: none;
    }
}

@media (min-width: 1024px) {
    .sticky_header.wide {
        display: none;
    }

    .stickyHeaderContainer.wide {
        display: none;
    }
}

.chip_wrapper{
    display: inline-flex;
    align-items: center;
    margin: 0.25rem;
}

.chip{
    display: flex;
    cursor: pointer;
    padding: 0.25rem 0.5rem;
    margin: 0.25rem;
    margin-right: 0.75rem;
    border-width: 1px;
    border-radius: 50px;
    border-style: solid;
    align-items: center;
}

.sortChip{
    display: flex;
    cursor: pointer;
    padding: 0.25rem 0.5rem;
    border-width: 1px;
    border-radius: 50px;
    border-style: solid;
    align-items: center;
}

.chip_contents{
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
}

.sort_chip_contents{
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
}

.chip_value{
    margin-right: 0.75rem;
    font-size: 0.75rem;
    font-weight: bold;
}

.dividerBottom{
    margin-top: 1px;
}

.vehicleSelectIcon{
    margin-top: -1px;
}

//NEW STUFF
.stickyHeaderContainer{
    display:flex;
    gap: 0.5rem;
}

.stickyHeaderButton{
    flex: 1 1 0;
}

.stickyHeaderPadding{
    padding: 0.5rem 0;
}

@media (min-width: 835px) {
    .stickyHeaderContainer {
        display: none;
    }
}

.forFrp{
    margin-top: 1rem;
}

.filterIcon{
    margin-right: 0.5rem;
}